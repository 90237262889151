
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    blok: {
      type: Object,
      required: true
    }
  }
})
