import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, resolveDirective as _resolveDirective, createElementVNode as _createElementVNode, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "container mt-3 mb-5 mt-md-0" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _directive_editable = _resolveDirective("editable")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _withDirectives(_createElementVNode("div", null, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.story.content.body, (item) => {
        return (_openBlock(), _createBlock(_resolveDynamicComponent(item.component), {
          key: item._uid,
          blok: item
        }, null, 8, ["blok"]))
      }), 128))
    ], 512), [
      [_directive_editable, _ctx.story]
    ])
  ]))
}