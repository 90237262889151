
import { defineComponent, ref } from 'vue'
import StoryblokClient from 'storyblok-js-client'
import Contact from '@/components/Contact.vue'

const storyapi = new StoryblokClient({
  accessToken: process.env.VUE_APP_API_TOKEN
})

export default defineComponent({
  components: {
    Contact
  },
  setup () {
    const story = ref({
      content: {
        body: [
          {
            component: ''
          }
        ]
      }
    })

    return {
      story
    }
  },
  methods: {
    getStory (slug: string) {
      storyapi.get('cdn/stories/' + slug)
        .then((response) => {
          this.story = response.data.story
        })
        .catch((error) => {
          console.log(error)
        })
    }
  },
  created () {
    window.storyblok.init({
      accessToken: process.env.VUE_APP_API_TOKEN
    })
    window.storyblok.on('change', () => {
      this.getStory('contact')
    })
    window.storyblok.pingEditor(() => {
      if (window.storyblok.isInEditor()) {
        this.getStory('contact')
      } else {
        this.getStory('contact')
      }
    })
  }
})
